"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast"

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Icon } from "@/components/ui/icon"
import sendMail, { sendToSalesForce } from "@/actions/mailActions"
import { cn } from "@/lib/utils"
import { useRouter } from "next/navigation"
import type { Affiliation } from "@/affiliations-config"
import { useRef, useState } from "react"

const FormSchema = z.object({
  name: z.string(),
  mobile: z.string(),
  lookingFor: z.string(),
})

export function HeroForm({
  className,
  affiliation,
  formId
}: {
  className?: string
  affiliation: Affiliation
  formId?: string
}) {
  const { toast } = useToast()
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [dropdownValue, setDropdownValue] = useState("")

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      mobile: "",
      lookingFor: "",
    },
  })

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    if (dropdownValue === "") {
      toast({
        variant: "destructive",
        description:
          "Please tell us what you are looking for by selecting a value from the dropdown",
      })
      return
    }
    setIsLoading(true)
    const emailBody = `
      <p>===============================================</p>
      <h1>${data.name} needs help with ${data.lookingFor}</h1>
      <h3>From: ${data.name}</h3>
      <h3>Contact Number: ${data.mobile}</h3>
      <h3>Looking For: ${data.lookingFor}</h3>
      <p>===============================================</p>
    `
    try {
      sendToSalesForce({ last_name: data.name, phone: data.mobile })
      const success = await sendMail(emailBody)
      if (success) {
        router.push(
          `/${affiliation}/thankyou?name=${encodeURIComponent(data.name)}`
        )
      }
    } catch (error) {
      console.log(error)
      toast({
        variant: "destructive",
        description: "Something went wrong. Please try again later",
      })
    }
    setIsLoading(false)
  }

  return (
    <Form {...form}>
      <form
        id={formId || "form"}
        onSubmit={form.handleSubmit((data) => onSubmit(data))}
        className={cn(
          "flex h-full w-full flex-col items-center justify-between gap-2 px-4 text-primary sm:flex-row",
          className
        )}
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="w-full sm:basis-1/5">
              <FormControl>
                <Input
                  placeholder="Enter your name"
                  {...field}
                  required
                  type="text"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mobile"
          render={({ field }) => (
            <FormItem className="w-full sm:basis-1/5">
              <FormControl>
                <Input
                  type="tel"
                  placeholder="Enter your mobile no."
                  {...field}
                  required
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lookingFor"
          render={({ field }) => (
            <FormItem className="w-full sm:basis-2/5">
              <Select
                onValueChange={(value) => {
                  field.onChange(value)
                  setDropdownValue(value)
                }}
                defaultValue={field.value}
                required
              >
                <FormControl>
                  <SelectTrigger className="text-muted-foreground">
                    <SelectValue placeholder="I am looking for...." />
                  </SelectTrigger>
                </FormControl>
                <SelectContent
                  className="text-muted-foreground"
                  ref={(ref) =>
                    ref?.addEventListener("touchend", (e) => {
                      e.preventDefault()
                    })
                  }
                >
                  <SelectItem value="Doctor Consultation">
                    Doctor Consultation
                  </SelectItem>
                  <SelectItem value="Health Checkup">Health Checkup</SelectItem>
                  <SelectItem value="General Enquiry">
                    General Enquiry
                  </SelectItem>
                  <SelectItem value="LifeSaver Training">
                    LifeSavER Training
                  </SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          className="w-full bg-custom-10 text-base font-medium sm:w-fit"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? "Verifying..." : "Submit"}
          <Icon
            name="heroicons--chevron-right-solid"
            className="h-4 w-4 pt-0.5"
          />
        </Button>
      </form>
    </Form>
  )
}
